import React from 'react';
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import $ from 'jquery';
// import {Heartland} from './securesubmit'
import axios from 'axios';
// let Heartland=Heartland();
declare var Heartland;
//  const x=value;
// const Heartland=Heartland as any
// const  Heartland=document.createElement('Heartland')
let zip = ''
var saveCard = ''
class PaymentForm extends React.Component {


  constructor(props) {
    super(props);
    const { history } = props;

    this.state = {
      id: '',
      hps: '',
      amount: "",
      zipcode: '',
      checked: false,
      regexp: /^[a-zA-Z0-9\b]+$/


    };

    // this.onPay = this.onPay.bind(this)
  }

  onHandleZipcodeChange = e => {
    let zipcode = e.target.value;


    // if value is not blank, then test the regex
    if (zipcode === '' || this.state.regexp.test(zipcode)) {
      if (zipcode.length <= 10) {
        zipcode = zipcode.substr(0, 10);
        this.setState({ [e.target.name]: zipcode })
      }
    }

  };
  onHandleCheckboxChange = e => {
    this.setState({
      checked: !this.state.checked

    })

  }
  // const __DEV__ = document.domain === 'localhost'
  // key: __DEV__ ? 'rzp_test_uGoq5ABJztRAhk' : 'PRODUCTION_KEY',
  async componentDidMount() {
    var CallOnce = true
    console.log(CallOnce)
    const __PROD__ = document.domain === 'payment.divrt.co'
    console.log(__PROD__)
    const host = window.location.hostname
    // console.log(host)
    if (host == 'localhost') {
      this.setState({ key: true })
      // console.log('true')
    }
    const parsed = queryString.parse(this.props.location.search);

    // console.log(parsed);
    // console.log(parsed.bid)
    // console.log(this.props)
    // console.log(parsed.amt)
    const amt = parsed.amt
    // if (parsed.amt.length != 0) {
    // console.log("not")
    this.setState({ amount: amt })
    // }
    // console.log(this.state.amount)
    // try{
    // const url = await fetch("https://pialleyapi-dev.divrt.co/api/v1/userConfigByBID/" + parsed.PIA)
    // // fetch(url).then((response) => {
    // //   response.json().then((response) => {
    //   const json = await url.json();
    //     console.warn(json)
    // // })
    this.setState({ id: parsed.bid })
    // }
    // catch(error){
    // console.log('error')
    // }
    // Create a new `HPS` object with the necessary configuration
    this.state.hps = new Heartland.HPS({
      publicKey: __PROD__ ? 'pkapi_prod_xsNDYycHiobektM4J8' : 'pkapi_cert_nfeseZqmULatOmu25Y',
      type: 'iframe',
      // Configure the iframe fields to tell the library where
      // the iframe should be inserted into the DOM and some
      // basic options
      fields: {
        cardNumber: {
          target: 'iframesCardNumber',
          placeholder: '•••• •••• •••• ••••'
        },
        cardExpiration: {
          target: 'iframesCardExpiration',
          placeholder: 'MM / YYYY'
        },
        cardCvv: {
          target: 'iframesCardCvv',
          placeholder: 'CVV'
        },
        submit: {
          value: 'Pay $' + parsed.amt,
          target: 'iframesSubmit'
        }
      },
      // 'Pay $20.00',
      // {this.state.id}
      // Collection of CSS to inject into the iframes.
      // These properties can match the site's styles
      // to create a seamless experience.
      style: {
        'input': {
          'background': 'darkgreen',
          'border': '1px solid',
          'border-color': '#bbb3b9 #c7c1c6 #c7c1c6',
          'box-sizing': 'border-box',
          'font-family': 'serif',
          'font-size': '16px',
          'line-height': '1',
          'margin': '0 .5em 0 0',
          'max-width': '100%',
          'outline': '0',
          'padding': '0.5278em',
          'vertical-align': 'baseline',
          'height': '50px@important',
          'width': '100% !important'
        },

        '#heartland-field': {
          'font-family': 'sans-serif',
          'box-sizing': 'border-box',
          'display': 'block',
          'height': '45px',
          'padding': '6px 12px',
          'font-size': '14px',
          'line-height': '1.42857143',
          'color': '#555',
          'background-color': '#fff',
          'border': '1px solid #ccc',
          'border-radius': '0px!important',
          // '-webkit-box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
          // 'box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
          // '-webkit-transition': 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
          // '-o-transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
          // 'transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',

          'width': '100%'
        },
        '#heartland-field[name=submit]': {
          'background-color': 'darkgreen',
          'font-family': 'sans-serif',
          'text-transform': 'uppercase',
          'color': '#ffffff',
          'border': 'none!important ',
          'opacity': '1!important',

        },
        '#heartland-field[name=submit]:focus': {
          'color': '#ffffff',
          'background-color': 'darkgreen',
          'outline': 'none'
        },
        '#heartland-field[name=submit]:hover': {
          'background-color': 'darkgreen'
        },
        '#heartland-field-wrapper #heartland-field:focus': {
          'border': '1px solid darkgreen!important',
          'outline': 'none',
          'box-shadow': 'none',
          'height': '45px!important',
          'opacity': '1!important',
          // 'border':'none!important',
          // 'border-radius': '0px!important'
        },
        'heartland-field-wrapper #heartland-field': {
          'height': '45px!important',
          'opacity': '1!important',
          'border': 'none!important',
          'border-radius': '0px!important'

        },
        'input[type=submit]': {
          'box-sizing': 'border-box',
          'display': 'inline-block',
          'padding': '6px 12px',
          'margin-bottom': '0',
          'font-size': '14px',
          'font-weight': '400',
          'line-height': '1.42857143',
          'text-align': 'center',
          'white-space': 'nowrap',
          'vertical-align': 'middle',
          '-ms-touch-action': 'manipulation',
          'touch-action': 'manipulation',
          'cursor': 'pointer',
          '-webkit-user-select': 'none',
          '-moz-user-select': 'none',
          '-ms-user-select': 'none',
          'user-select': 'none',
          'background-image': 'none',
          'border': '0px solid darkgreen',
          'border-radius': '0px!important',
          'color': '#fff',
          'background-color': 'darkgreen',
          'border-color': 'darkgreen'
        },
        '#heartland-field[placeholder]': {
          'letter-spacing': '3px'
        },
        '#heartland-field[name=cardCvv]': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/cvv1.png?raw=true) no-repeat right',
          'background-size': '63px 40px',
        },
        'input#heartland-field[name=cardNumber]': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-inputcard-blank@2x.png?raw=true) no-repeat right',
          'background-size': '55px 35px'
        },
        '#heartland-field.invalid.card-type-visa': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right',
          'background-size': '83px 88px',
          'background-position-y': '-44px'
        },
        '#heartland-field.valid.card-type-visa': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right top',
          'background-size': '82px 86px'
        },
        '#heartland-field.invalid.card-type-discover': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
          'background-size': '85px 90px',
          'background-position-y': '-44px'
        },
        '#heartland-field.valid.card-type-discover': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
          'background-size': '85px 90px',
          'background-position-y': '1px'
        },
        '#heartland-field.invalid.card-type-amex': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right',
          'background-size': '50px 90px',
          'background-position-y': '-44px'
        },
        '#heartland-field.valid.card-type-amex': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right top',
          'background-size': '50px 90px'
        },
        '#heartland-field.invalid.card-type-mastercard': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
          'background-size': '62px 105px',
          'background-position-y': '-52px'
        },
        '#heartland-field.valid.card-type-mastercard': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
          'background-size': '62px 105px',
          'background-position-y': '-1px'
        },
        '#heartland-field.invalid.card-type-jcb': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right',
          'background-size': '55px 94px',
          'background-position-y': '-44px'
        },
        '#heartland-field.valid.card-type-jcb': {
          'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right top',
          'background-size': '55px 94px',
          'background-position-y': '2px'
        },
        'input#heartland-field[name=cardNumber]::-ms-clear': {
          'display': 'none'
        }
      },


      onTokenSuccess: function (resp) {

        // var element=document.getElementById('iframesSubmit')
        // element.classList.add('disableBtn')
        $('#loader').removeClass("hide-loader1")
        $('#loader').addClass("hide-loader");
        // console.log("success")
        // alert('Here is a single-use token: ' + resp.token_value);
        // window.location.replace("http://www.w3schools.com");
        // (async () => {
        var value = resp
        // console.log(value)
        //   const response = await
        var year = value['exp_year']
        // console.log(year)
        var month = value['exp_month']
        // console.log(month)
        if (year.length == 0) {
          alert('Enter complete year and month to do the payment');
          $('#loader').addClass("hide-loader1");
          var element = document.getElementById('iframesSubmit')
          element.classList.remove('disableBtn')
          return
        }
        console.log(year)
        var UTCyear = new Date().getUTCFullYear()
        var UTCmonth = new Date().getUTCMonth()

        // if (year < UTCyear) {
        //   alert('You entered an expiration date that has already passed. Please re-enter valid card details');
        //   $('#loader').addClass("hide-loader1");
        //   return
        // }
        // if (year == UTCyear) {
        //   if (month >= UTCmonth + 1) {

        //   }
        //   else {
        //     alert('You entered an expiration date that has already passed. Please re-enter valid card details');
        //     $('#loader').addClass("hide-loader1");
        //     return
        //   }
        // }

        if (zip.length == 0) {
          alert('Enter the zip code to do the payment');
          $('#loader').addClass("hide-loader1");
          var element = document.getElementById('iframesSubmit')
          element.classList.remove('disableBtn')
          return
        }
        // console.log(zip)
        // console.log(parsed.PIA)
        var senddata = {
          "bid": parsed.bid, "token": value['token_value'], "expiry_month": month,
          "expiry_year": year, "cardNo": value['last_four'], "amount": parsed.amt, "newCard": 1,
          "address": { "city": "", "country": "", "firstName": "", "lastName": "", "state": "", "street": "", "zip": zip }, "sharedata": value, "rememberCard": saveCard
        }
        // console.log(senddata)
        // document.getElementById('iframesSubmit').style.display='none'

        // var element=document.getElementById('iframesSubmit')
        // element.classList.add('disableBtn')
        // console.log(CallOnce)
        if (CallOnce) {
          const sendPostRequest = async () => {

            try {
              CallOnce=false
              console.log(CallOnce)
              const resp = await axios.post('https://pialleyapi-dev.divrt.co/api/v1/dailyPassPayment', senddata);
              // console.log(resp.data);

              if (resp.data.status == false) {
                $('#loader').addClass("hide-loader1");
                // document.getElementById('iframesSubmit').style.display='block'
                var element = document.getElementById('iframesSubmit')
                element.classList.remove('disableBtn')
               
                alert(resp.data.message)
                setTimeout(()=>{
                  CallOnce = true
                  $('#loader').addClass("hide-loader1");
                  console.log(CallOnce)
                },1000)
              
              } else {
                $('#loader').addClass("hide-loader");

                window.location.replace('https://pialleygarage.com/status/?hbid=' + parsed.bid)
              }



            } catch (err) {
              // Handle Error Here
              console.error(err);
            }
          };
          // callloader()
          sendPostRequest();
        }
      },
      // Callback when an error is received from the service
      onTokenError: function (resp) {

        // console.log(resp)
        alert('There was an error: ' + resp.error.message);
        var element = document.getElementById('iframesSubmit')
        element.classList.remove('disableBtn')
      },
      // Callback when an event is fired within an iFrame
      onEvent: function (ev) {
      
        if (ev.source == 'submit' && ev.type == 'click') {
          console.log('click')
          // ev.stopImmediatePropagation();
          var element = document.getElementById('iframesSubmit')
          element.classList.add('disableBtn')
        }

      }

    })


  }
  componentWillUnmount() {

    this.state.hps.dispose();
    // console.log('clicked 3')
  }

  render() {
    const { url } = this.props.match
    // console.log(url)
    // console.log(this.props)
    zip = this.state.zipcode
    if (this.state.checked) {
      // console.log("hello")
      saveCard = 1
    }
    else {
      saveCard = 0
    }
    // console.log(saveCard)
    // console.log(zip)



    return (
      <div>
        <div className="form-wrapper">

          <form id="iframes" action="" method="GET">
            <div id="ss-banner" />

            <div className="form-row">
              <label htmlFor="iframesCardNumber">Card Number:</label>
              <div id="iframesCardNumber" />
            </div>

            <div className="form-row">
              <label htmlFor="iframesCardExpiration">Card Expiration:</label>
              <div id="iframesCardExpiration" />
            </div>

            <div className="form-row">
              <label htmlFor="iframesCardCvv">Card CVV:</label>
              <div id="iframesCardCvv" />
            </div>
            <div className="form-row">
              < label htmlFor="iframesZip">Zip Code: </ label >

              <input className="zip" style={{ fontFamily: 'sans-serif', fontSize: '14px' }} autoComplete='off' type="text" name="zipcode" placeholder="ZIP CODE"
                value={this.state.zipcode} onChange={this.onHandleZipcodeChange}
              />
            </div>


            <div style={{ paddingTop: '14px', fontSize: '15px' }}>
              <label>
                <input style={{ fontFamily: 'sans-serif', fontSize: '14px' }} type="checkbox" checked={this.state.checked} onChange={this.onHandleCheckboxChange} />
        Save the card for future bookings.
       </label>
            </div>
            {/* <br /> */}
            <div className="form-row">
              <div id="iframesSubmit"
              // style={{backgroundColor: '#36b46e',width: '298px!important',border: 'none!important',opacity: '1!important'}}
              />
            </div>
            <div class="loader" id="loader" style={{ marginTop: "12px" }}>
            </div>
          </form>
        </div>

      </div>

    );
  }
}

export default PaymentForm



